import "~/controllers";
import "@hotwired/turbo-rails";

if (typeof Sentry != "undefined") {
  Sentry.onLoad(() => {
    Sentry.init({
      environment: import.meta.env.RAILS_ENV,
    });
  });
}
