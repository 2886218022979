import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    href: String,
  };

  connect() {
    this.originalHTML = this.element.innerHTML;
  }

  async copy(event) {
    event.preventDefault();
    await navigator.clipboard.writeText(this.hrefValue);

    this.element.innerHTML = "Copied!";
    setTimeout(() => (this.element.innerHTML = this.originalHTML), 2000);
  }
}
